import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';

import './header.css';

export default function Header(props) {

    const navigate = useNavigate();

    const logout = () => {
        //TODO Implementar lógica do login
        navigate('/');
    }

    return (
        <div className="header">
            <h1 className='tituloHeader'>DILIGENCIAMENTO</h1>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 20 }}>
                {/* <Button onClick={props.export} text="Exportar para o Excel" style={{marginRight: 40, backgroundColor: "#2e7d32"}} /> */}
                <label className='ultimaAtualizacao'>Ultima Atualização: <label style={{ fontWeight: 500, color: "#eee" }}>{props.ultimaAtualizacao}</label></label>
                <h5 className='nomeUsuario'>{props.usuarioLogado}</h5>
                <label className='sair' onClick={logout}>sair</label>
            </div>
        </div>
    );
}
