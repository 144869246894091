import axios from 'axios';
import { Buffer } from "buffer";

//Preparando Token
const username = 'fluig';
const password = 'Centrium505050@@';
const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

//Instanciando o axios
const api = axios.create({
    baseURL: "https://pelicanoconstrucoes127067.rm.cloudtotvs.com.br:8051/api/framework/v1/consultaSQLServer/RealizaConsulta",
    headers: {
        Authorization: `Basic ${token}`
    }
});

export default api;