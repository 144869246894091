function checarErros(error) {

    console.log("Ocorreu um erro: " + error);

    //Erro de licenças
    if (error.message.includes("Request failed with status code 500") != -1) {
        // console.log("Sem licenças no RM");
        return ("licença");
    }
}

export { checarErros }