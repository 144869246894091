import "./checkbox.css";

export default function Checkbox(props) {

    const descricao = props.descricao;
    const checked = props.checked;
    const onChange = props.onChange;

    return (
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10, marginBottom: 10 }}>
            <input type="checkbox" style={{ width: 16, height: 16 }} checked={checked} onChange={(event) => props.onChange(event.target.checked)} />
            <label style={{ marginLeft: 5 }}>{descricao}</label>
        </div>
    );
}