function salvar(chave, valor) {
    localStorage.setItem(chave, JSON.stringify(valor));
}

function obter(chave) {
    if(localStorage.getItem(chave) == undefined || localStorage.getItem(chave) == null){
        return [];
    }
    else{
        return JSON.parse(localStorage.getItem(chave));
    }
}

function excluir(chave) {
    localStorage.removeItem(chave);
}

export {
    salvar,
    obter,
    excluir
}