import React from "react";
import { Route, Routes, Switch, BrowserRouter } from "react-router-dom";

//Páginas
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
// import SelecaoOS from "../pages/SelecaoOS";
// import SelecaoItens from "../pages/SelecaoItens";
// import DetalhesItem from "../pages/DetalhesItem";

const Rotas = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} exact />
                <Route path="/dashboard" element={<Dashboard />} exact />
                {/* <Route path="/os" element={<SelecaoOS />} />
                <Route path="/os/:id" element={<SelecaoItens />} />
                <Route path="/os/:idOS/:idItem?" element={<DetalhesItem />} /> */}
                <Route path="/*" element={<Login />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Rotas;