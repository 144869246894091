import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import api from "../../services/api";

import { checarErros } from '../../utils/identificarErros';


import { FaLongArrowAltRight, FaEnvelope, FaWhatsapp, FaTimes } from 'react-icons/fa';

export default function ExibirRastreamentos(props) {

    const [exibirContatosResponsavel, setExibirContatosResponsavel] = useState(false);
    const [email, setEmail] = useState("");
    const [whatsApp, setWhatsApp] = useState("");

    const obterInformacoesDoUsuario = (nome) => {
        let nomeDoUsuario = nome.includes(".") ? nome.split(".")[0] : nome.includes(" ") ? nome.split(" ")[0] : nome;

        setEmail("Não Informado");
        setWhatsApp("Não Informado");

        api.get(`https://pelicanoconstrucoes127067.rm.cloudtotvs.com.br:8051/api/framework/v1/consultaSQLServer/RealizaConsulta/660601/1/P?parameters=NOME=${nomeDoUsuario}`)
            .then((response) => {
                // console.log(response.data);
                response.data.forEach(element => {
                    //Obtendo email e whatsapp| do usuário
                    if (element.email != undefined) {
                        const nomeParaComparar = nome.includes(" ") ? nome.replace(" ", ".") : nome;
                        console.log(nomeParaComparar);
                        if (nomeParaComparar.toLowerCase() == element.email.split("@")[0]) {
                            const emailResponsavel = element.email;
                            const whatsAppResponsavel = element.telefone || "Não Informado";
                            setEmail(emailResponsavel);
                            setWhatsApp(whatsAppResponsavel);
                        }
                    }
                    setExibirContatosResponsavel(true);
                });
            })
            .catch((error) => {
                console.log("Ocorreu um erro: " + error);
                if (checarErros(error) == "licença") {
                    props.setSemLicencas(true);
                }
            });

        // setExibirContatosResponsavel(true);
    }

    return (
        <div className='containerCardsRastreamento'>
            {props.rastreamentos.map(function (rastreamento, indice) {
                return (
                    <div key={indice} style={{ display: "flex", alignItems: "center" }}>
                        <div className={indice == (props.rastreamentos.length - 1) ? 'cardRastreamento ultimoCard' : 'cardRastreamento'}>
                            <h4>{rastreamento.titulo}</h4>
                            <p>Número: {rastreamento.numero}</p>
                            <p>Data Emissão: {rastreamento.dataEmissao}</p>
                            <p>{rastreamento.tempoEmDias != undefined ? "Tempo em Dias: " + rastreamento.tempoEmDias : ""}</p>
                            <p>{rastreamento.status != undefined ? "Status: " + rastreamento.status : ""}</p>
                            <p>Responsável: <label /*className='linkResponsavel' onClick={() => obterInformacoesDoUsuario(rastreamento.responsavel)}*/>{rastreamento.responsavel}</label></p>
                        </div>
                        {
                            (indice != (props.rastreamentos.length - 1)) ?
                                <FaLongArrowAltRight size={30} color="#555" style={{ marginRight: 20 }} />
                                : null
                        }
                    </div>
                )
            })}
            <Dialog onClose={() => setExibirContatosResponsavel(false)} open={exibirContatosResponsavel}>
                <DialogTitle>Escolha um meio de contato</DialogTitle>
                <List sx={{ pt: 0 }}>
                    {email != "Não Informado" ?
                        <ListItem disableGutters>
                            <ListItemButton onClick={() => window.open("mailto:" + email + "?subject=Diligenciamento - " + props.item)} key={"ti@pelicano.eng.br"}>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                        <FaEnvelope size={22} color="#1e88e5" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={"E-mail"} />
                            </ListItemButton>
                        </ListItem>
                        :
                        <ListItem disableGutters>
                            <ListItemButton>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: "#ffcdd2" }}>
                                        <FaTimes size={22} color="#f44336" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={"E-mail não cadastrado"} />
                            </ListItemButton>
                        </ListItem>
                    }
                    {whatsApp != "Não Informado" ?
                        <ListItem disableGutters>
                            <ListItemButton onClick={() => window.open("https://wa.me/55" + whatsApp)} key={"ti@pelicano.eng.br"}>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: "#c8e6c9" }}>
                                        <FaWhatsapp size={22} color="#388e3c" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={"WhatsApp"} />
                            </ListItemButton>
                        </ListItem>
                        :
                        <ListItem disableGutters>
                            <ListItemButton>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: "#ffcdd2" }}>
                                        <FaTimes size={22} color="#f44336" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={"WhatsApp não cadastrado"} />
                            </ListItemButton>
                        </ListItem>
                    }
                </List>
            </Dialog>
        </div >
    )
}