import api from "../../services/api";
import { FaCheck } from 'react-icons/fa';

import { Tooltip } from "@mui/material";

import { checarErros } from "../../utils/identificarErros";

export default function CardResumo(props) {

    let color;
    let numeroDoMovimentoDoCard = "";

    switch (props.titulo) {
        case 'Oficina':
            numeroDoMovimentoDoCard = ["1.1.01"];
            break;

        case 'PMS':
            numeroDoMovimentoDoCard = ["1.1.01"];
            break;

        case 'Aprov Téc.':
            numeroDoMovimentoDoCard = ["1.1.01"];
            break;

        case 'Aprov Ger.':
            numeroDoMovimentoDoCard = ["1.1.01"];
            break;

        case 'Aprov Inst.':
            numeroDoMovimentoDoCard = ["1.1.01"];
            break;

        case 'Almoxarifado':
            numeroDoMovimentoDoCard = ["1.1.02"];
            break;

        case 'Baixado':
            numeroDoMovimentoDoCard = ["1.1.03", "3.1.04"];
            break;

        case 'Suprim.':
            numeroDoMovimentoDoCard = ["1.1.04"];
            break;

        case 'Cotações':
            numeroDoMovimentoDoCard = ["cotacao"];
            break;

        case 'Compras':
            numeroDoMovimentoDoCard = ["1.1.06"];
            break;

        case 'PCU':
            numeroDoMovimentoDoCard = ["1.1.07"];
            break;

        case 'Diligenc.':
            numeroDoMovimentoDoCard = ["1.1.08"];
            break;

        case 'Disponível':
            numeroDoMovimentoDoCard = ["1.2.01", "1.2.02", "1.2.03", "1.2.04"];
            break;

        case 'Itens O.S 3°':
            numeroDoMovimentoDoCard = ["1.1.16"]
            break;

        case 'Itens O.S':
            numeroDoMovimentoDoCard = ["Não existe movimento"]
            break;

        default:
            numeroDoMovimentoDoCard = ["%"]
            break;
    }

    const filtrar = () => {

        props.setLoading(true);

        //Obter ultimos movimentos dos itens de cada O.S
        const url = props.iniciarEm == "1.1.15" ?
            (`/660660/1/G/?parameters=DATAI_S=${props.dataInicio};DATAF_S=${props.dataFim};STATUS_S=${props.statusOS};CODLOC_S=${props.localDeEstoque.split(" - ")[0]};EQUIPAMENTO_S=${props.codEquip};FILIAL_S=${props.codFilial};RESPONSAVEL_S=${props.nomeResponsavel}`) :
            (`/660666/1/G/?parameters=DATAI_S=${props.dataInicio};DATAF_S=${props.dataFim};STATUS_S=${props.statusOS};CODLOC_S=${props.localDeEstoque.split(" - ")[0]};EQUIPAMENTO_S=${props.codEquip};FILIAL_S=${props.codFilial};RESPONSAVEL_S=${props.nomeResponsavel}`);

        api.get(url)
            .then((response) => {
                let resultadoOsFiltradas = [];
                let listaOS = [];

                response.data.forEach(response => {
                    //Removendo as O.S / PMS duplicadas
                    if (!listaOS.includes(response.OSNUMEROMOV)) {
                        listaOS.push(response.OSNUMEROMOV);
                    }

                    if ((numeroDoMovimentoDoCard[0] == "cotacao") && (response.COTACAO == '1') && (!resultadoOsFiltradas.includes(response.OSNUMEROMOV))) {
                        resultadoOsFiltradas.push(response.OSNUMEROMOV);
                    }
                    //Filtrando os itens da O.S / PMS
                    else if (((numeroDoMovimentoDoCard.includes(response.ULTIMO_MOVIMENTO)) || (numeroDoMovimentoDoCard[0] == "%")) && (!resultadoOsFiltradas.includes(response.OSNUMEROMOV))) {
                        resultadoOsFiltradas.push(response.OSNUMEROMOV);
                    }
                })

                console.log(resultadoOsFiltradas);

                props.setOsFiltradas(resultadoOsFiltradas);
                props.setLoading(false);

            }).catch((error) => {
                console.log(error);
                props.setLoading(false);
                if (checarErros(error) == "licença") {
                    props.setSemLicencas(true);
                }
            });

        //Definindo elemento de filtragem
        props.filtroSelecionado != props.titulo ? props.setFiltroSelecionado(props.titulo) : props.setFiltroSelecionado(props.filtroSelecionado);
        console.log(props.filtroSelecionado);
    }

    switch (props.titulo) {
        case 'Total O.S':
            color = "#222222";
            break;

        case 'Total PMS':
            color = "#222222";
            break;

        case 'Oficina':
            color = "#3EA2ED";
            break;

        case 'Aprov Téc.':
            color = "#1a237e   ";
            break;

        case 'Aprov Ger.':
            color = "#1a237e   ";
            break;

        case 'Aprov Inst.':
            color = "#1a237e   ";
            break;

        case 'PMS':
            color = "#3EA2ED";
            break;

        case 'Almoxarifado':
            color = "#1976d2";
            break;

        case 'Baixado':
            color = "#777777";
            break;

        case 'Suprim.':
            color = "#C99100";
            break;

        case 'Cotações':
            color = "#333";
            break;

        case 'Compras':
            color = "#9c27b0";
            break;

        case 'PCU':
            color = "#009688";
            break;

        case 'Diligenc.':
            color = "#f44336";
            break;

        case 'Disponível':
            color = "#06C13B";
            break;

        case 'Itens O.S':
            color = "#e57373";
            break;

        case 'Itens O.S 3°':
            color = "#ff7043";
            break;

        case 'Itens PMS':
            color = "#e57373";
            break;
    }
    return (
        <Tooltip title={props.movimento}>
            <div className={props.desativado ? 'containerCardResumoDisabled' : 'containerCardResumo'} onClick={props.desativado ? null : filtrar} style={{ borderColor: color, marginBottom: 15 }} >
                <div className="cabecalhoCardResumo" style={{ backgroundColor: color }}>
                    <h4 style={{ userSelect: "none", fontWeight: 500 }}>{props.titulo}</h4>
                    {/* <label>{props.movimento}</label> */}
                </div>
                <div className='bodyCardResumo'>
                    {(props.filtroSelecionado == props.titulo) ? (
                        <FaCheck color='green' size={20} style={{ marginRight: 5 }} />
                    ) : null}
                    <h4 style={{ color: color, fontWeight: 500, userSelect: "none" }}>{props.valor}</h4>
                </div>
            </div>
        </Tooltip>
    );
}