import "./button.css";
import { FaFilter, FaFileExport, FaUpload, FaArrowLeft } from "react-icons/fa";

export default function Button(props) {

    const text = props.text;
    const style = props.style;
    const onClick = props.onClick;
    const icon = props.icon;
    const iconName = props.iconName;
    const loading = props.loading;

    let componenteIcone;

    if (icon) {
        switch (iconName) {
            case "FaFilter":
                componenteIcone = <FaFilter size={16} color="#eee" />;
                break;

            case "FaFileExport":
                componenteIcone = <FaFileExport size={18} color="#eee" />;
                break;

            case "FaUpload":
                componenteIcone = <FaUpload size={17} color="#eee" />;
                break;

            case "FaArrowLeft":
                componenteIcone = <FaArrowLeft size={20} color="#eee" />;
                break;

            default:
                componenteIcone = null;
                break;
        }
    }

    return (
        <div ref={props.referencia} onClick={loading ? null : onClick} className={loading ? "containerButton loading" : "containerButton"} style={style}>
            {icon ? componenteIcone : null}
            <button className="button">{loading ? "Aguarde" : text}</button>
        </div>
    );
}