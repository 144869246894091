import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import axios from 'axios';
import { salvar, obter, excluir } from '../../utils/localStorage';

//Componentes
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';

//Mui
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';

import './login.css';

const username = 'fluig';
const password = 'Centrium505050@@';
const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

const Card = (props) => {
    const validarCodigo = (event, mensagem = "") => {
        if (event.target.value == "123456") {
            props.setRedefinicaoValida(true);
            props.setCodigoInvalido(false);
        }
        else {
            if (mensagem == "validar") {
                props.setCodigoInvalido(true);
            }
            props.setRedefinicaoValida(false);
        }
    }

    if (props.tipo == "login") {
        return (
            <div className="card">
                <div style={{ textAlign: "center" }}>
                    <img className="logoCard" src={require("../../assets/icone-200X200.png")} />
                    <h3 style={{ color: "#333", fontWeight: 500, fontSize: 18, marginTop: 10 }}> SISTEMA DE DILIGENCIAMENTO</h3>
                </div>

                <div style={{ flex: 1 }}>

                    <Input
                        titulo="Login"
                        placeholder="nome.sobrenome"
                        value={props.login}
                        onChange={event => props.setLogin(event.target.value.toLowerCase())}
                    />

                    <Input
                        titulo="Senha"
                        placeholder="senha"
                        style={{ marginTop: 15 }} type="password"
                        value={props.senha}
                        onChange={event => props.setSenha(event.target.value)}
                    // mensagemErro="Senha inválida"
                    />
                    <Checkbox descricao="Manter Conectado" checked={props.manterConectado} onChange={props.setManterConectado} />
                </div>

                <Button text="ENTRAR" style={{marginBottom: 50}} onClick={() => props.fazerLogin(props.login, props.senha)} />

                {/* <a href="#" className="link">
                    <h5 onClick={() => props.acao("redefinicaoSenha")} style={{ color: "#777", textAlign: "center", fontWeight: 500, marginTop: 50 }}>Esqueci a Senha</h5>
                </a> */}
            </div>
        );
    }
    else if (props.tipo == "redefinicaoSenha") {
        return (
            <div className="card">
                <div style={{ textAlign: "center" }}>
                    <img className="logoCard" src={require("../../assets/logo_reduzida.png")} />
                    <h3 style={{ color: "#333", fontWeight: 500, fontSize: 22, marginTop: 10 }}>Redefinição de Senha</h3>
                </div>

                <div style={{ flex: 1 }}>
                    <Input titulo="E-mail" placeholder="Seu email" />
                    <label className='legenda'>Obs: Você receberá um e-mail com código de redefinição de senha</label>
                </div>

                <Button
                    text="ENVIAR CÓDIGO"
                    style={{ width: "100%" }}
                    onClick={() => props.acao("codigoRedefinicao")}
                />

                <a href="#" className="link">
                    <h5 onClick={() => props.acao("login")} style={{ color: "#777", fontWeight: "bold", marginTop: 50 }}>Voltar</h5>
                </a>
            </div>
        )
    }
    else if (props.tipo == "codigoRedefinicao") {
        return (
            <div className="card">
                <div style={{ textAlign: "center" }}>
                    <img className="logoCard" src={require("../../assets/logo_reduzida.png")} />
                    <h3 style={{ color: "#333", fontWeight: 500, fontSize: 22, marginTop: 10 }}>Redefinição de Senha</h3>
                </div>

                <div style={{ flex: 1 }}>
                    <Input
                        titulo="Código de Redefinição"
                        placeholder="Informe o código aqui"
                        onblur={(event) => validarCodigo(event, "validar")}
                        value={props.codigoRedefinicao}
                        onChange={event => [props.setCodigoRedefinicao(event.target.value), validarCodigo(event)]}
                        mensagemErro={props.codigoInvalido ? "Código inválido" : undefined}
                    />
                    {/* <label className='legenda'>Obs: Você receberá um e-mail com código de redefinição de senha</label> */}
                </div>

                {props.redefinicaoValida ?
                    <div>
                        <Input
                            titulo="Nova Senha"
                            placeholder="Informe sua nova senha"
                            value={props.novaSenha}
                            type="password"
                            onChange={event => props.setNovaSenha(event.target.value)}
                            style={{ marginBottom: 20 }}
                        />
                        <Button
                            text="SALVAR NOVA SENHA"
                            style={{ width: "100%" }}
                            onClick={() => props.acao("codigoRedefinicao")}
                        />
                    </div>
                    :
                    <div></div>
                }

                <a href="#" className="link">
                    <h5 onClick={() => props.acao("redefinicaoSenha")} style={{ color: "#777", fontWeight: "bold", marginTop: 50 }}>Voltar</h5>
                </a>
            </div>
        )
    }
}

export default function Login() {

    const navigate = useNavigate();

    const [cardVisivel, setCardVisivel] = useState("login");
    const [login, setLogin] = useState("");
    const [senha, setSenha] = useState("");
    const [redefinicaoValida, setRedefinicaoValida] = useState(false);
    const [codigoRedefinicao, setCodigoRedefinicao] = useState("");
    const [codigoInvalido, setCodigoInvalido] = useState(false);
    const [modalNovaSenha, setModalNovaSenha] = useState("");
    const [novaSenha, setNovaSenha] = useState("");
    const [dialog, setDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [manterConectado, setManterConectado] = useState(false);
    const [atualizarEstados, setAtualizarEstados] = useState(false);

    useEffect(() => {
        if (obter("dadosDeLogin").usuario != undefined) {
            setManterConectado(obter("manterConectado"));
            setLogin(obter("dadosDeLogin").usuario);
            setSenha(obter("dadosDeLogin").senha);
            fazerLogin(obter("dadosDeLogin").usuario, obter("dadosDeLogin").senha);
        }
    }, [])

    const trocarCard = (tela) => {
        setCardVisivel(tela);
    }

    const fazerLogin = (login, senha) => {
        //TODO Implementar lógica do login

        const usuarioLogin = login;
        const senhaLogin = senha;

        if ((usuarioLogin != "") && (senhaLogin != "")) {

            //Início do login
            setLoading(true);

            //Verificando se o usuário esta ativo no RM
            axios.get(
                `https://pelicanoconstrucoes127067.rm.cloudtotvs.com.br:8051/api/framework/v1/consultaSQLServer/RealizaConsulta/660602/1/G/?parameters=CODUSUARIO=${usuarioLogin}`,
                {
                    headers: { 'Authorization': `Basic ${token}` }
                }
            ).then(response => {

                if (response.data.length > 0) {

                    let data = {
                        operacao: "autenticar",
                        usuario: usuarioLogin,
                        senha: senhaLogin,
                    };

                    // console.log(response.data);

                    //Verificando se usuário ja existe na base JSON
                    axios.post(`https://almox.pelicano.eng.br/apis/bancoDeUsuariosAppOS/usuariosOSBancoJSON.php`,
                        { data }
                    ).then(response => {

                        //Caso usuário ainda não esteja na base JSON
                        if (response.data == "nao encontrado") {

                            setLoading(false);

                            if (senha != "pelicano30") {
                                setLoading(false);
                                setDialogTitle("Senha Incorreta");
                                setDialogMessage("Verifique a senha digitada e tente novamente");
                                setDialog(true);
                            }
                            else {
                                setModalNovaSenha(true);
                            }
                        }
                        else if (response.data == "nao autenticado") {
                            setLoading(false);
                            setDialogTitle("Senha Incorreta");
                            setDialogMessage("Verifique a senha digitada e tente novamente");
                            setDialog(true);
                        }
                        else if (response.data == "autenticado") {
                            console.log(manterConectado);
                            salvar("manterConectado", manterConectado);
                            if (manterConectado) {
                                salvar("dadosDeLogin", { usuario: login, senha: senha });
                            }
                            else {
                                excluir("dadosDeLogin");
                                excluir("manterConectado");
                            }
                            navigate("dashboard", { state: { autenticado: true, usuario: login } });
                        }
                        setLoading(false);

                    }).catch((erro) => {
                        setLoading(false);
                        setDialogTitle("Ocorreu um erro ao realizar login");
                        setDialogMessage("Detalhes do erro: " + erro);
                        console.log(erro);
                        setDialog(true);
                    })
                }
                else {
                    setLoading(false);
                    setDialogTitle("Usuário não encontrado");
                    setDialogMessage("O usuário " + usuarioLogin + " esta inativo ou não existe no RM");
                    setDialog(true);
                }
                //     }).catch ((erro) => {
                //         setLoading(false);
                //         setDialogTitle("Ocorreu um erro ao realizar login");
                //         setDialogMessage("Detalhes do erro: " + erro);
                //         setDialog(true);
                //     })

                // }
                //         else {
                //     setDialogTitle("Dados incorretos");
                //     setDialogMessage("Você precisa informar login e senha para entrar");
                //     setDialog(true);
                // }

                // navigate('/dashboard');
            }).catch((erro) => {
                setLoading(false);
                setDialogTitle("Não foi possivel consultar o usuário no RM");
                setDialogMessage("Detalhes do erro: " + erro);
                console.log(erro);
                setDialog(true);
            })
        }
        else {
            setLoading(false);
            setDialogTitle("Campos inválidos");
            setDialogMessage("Por favor preencha login e senha corretamente");
            setDialog(true);
        }
    }

    const cadastrarUsuarioNoJSON = () => {

        let data = {
            usuario: login,
            senha: senha,
            operacao: "novoUsuario",
        }

        setLoading(true);

        //Cadastrando um novo usuario na base JSON
        axios.post(`https://almox.pelicano.eng.br/apis/bancoDeUsuariosAppOS/usuariosOSBancoJSON.php`,
            { data }).then(response => {
                console.log(response.data);
                if (response.data == "Adicionado com sucesso") {
                    salvar("manterConectado", manterConectado);
                    if (manterConectado) {
                        salvar("dadosDeLogin", { usuario: login, senha: senha });
                    }
                    else {
                        excluir("dadosDeLogin");
                        excluir("manterConectado");
                    }
                    navigate("dashboard", { state: { autenticado: true, usuario: login } });
                }
                else {
                    setDialogTitle("Problema ao gravar usuário");
                    setDialogMessage("Ocorreu um problema ao gravar usuário no banco JSON, consulte o log");
                    console.log(response.data);
                    setDialog(true);
                }
                setLoading(false);
            }).catch((erro) => {
                setLoading(false);
                setDialogTitle("Erro ao comunicar com servidor");
                setDialogMessage("Ocorreu um erro ao se comunicar com a base JSON, consulte o log");
                console.log(erro);
                setDialog(true);
            })
    }

    return (
        <div className="containerLogin">
            <Card
                tipo={cardVisivel}
                acao={trocarCard}
                login={login}
                setLogin={setLogin}
                senha={senha}
                setSenha={setSenha}
                redefinicaoValida={redefinicaoValida}
                setRedefinicaoValida={setRedefinicaoValida}
                codigoRedefinicao={codigoRedefinicao}
                setCodigoRedefinicao={setCodigoRedefinicao}
                codigoInvalido={codigoInvalido}
                setCodigoInvalido={setCodigoInvalido}
                novaSenha={novaSenha}
                setNovaSenha={setNovaSenha}
                fazerLogin={fazerLogin}
                manterConectado={manterConectado}
                setManterConectado={setManterConectado}
            />
            <Dialog onClose={() => setDialog(false)} open={dialog}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <div className="corpoDialog">
                        {dialogMessage}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button text="Entendi" onClick={() => setDialog(false)} />
                </DialogActions>
            </Dialog>

            <Dialog onClose={() => setModalNovaSenha(false)} open={modalNovaSenha}>
                <DialogTitle>Cadastre nova senha</DialogTitle>
                <DialogContent>
                    <Input
                        titulo="Nova senha"
                        placeholder=""
                        value={novaSenha}
                        type="password"
                        onChange={event => setNovaSenha(event.target.value)}
                    />
                </DialogContent>
                <DialogActions style={{ padding: 25, paddingTop: 0, paddingBottom: 20 }}>
                    <Button text="Salvar" onClick={() => cadastrarUsuarioNoJSON()} />
                </DialogActions>
            </Dialog>

            <Modal
                open={loading}
                onClose={() => setLoading(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='containerModal'>
                    <h3 style={{ fontWeight: 500, fontSize: 18 }}>Autenticando no RM</h3>
                    <CircularProgress />
                    <p style={{ fontSize: 14, color: "#444" }}>Aguarde um instante</p>
                </div>
            </Modal>
        </div>
    );
}