import React, { useState } from 'react';
import "./input.css";
import { IoEye, IoEyeOffSharp, IoSearchOutline } from 'react-icons/io5';

export default function Input(props) {

    const titulo = props.titulo;
    const placeholder = props.placeholder;
    const style = props.style;
    const type = props.type || "text";
    const mensagemErro = props.mensagemErro;
    const onblur = props.onblur;
    const value = props.value;
    const onChange = props.onChange;

    const button = props.button;
    const icon = props.icon;
    const onClick = props.onClick;

    const [typeAtual, setTypeAtual] = useState(type);
    const [exibindoSenha, setExibindoSenha] = useState(false);

    return (
        <div className="divInput" style={style} >
            <label className="label">{titulo}</label>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                <div className={mensagemErro != undefined ? "containerInputErro" : "containerInput"} style={button ? { borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: 0 } : {}}>
                    <input
                        type={typeAtual}
                        className="input"
                        placeholder={placeholder}
                        onBlur={onblur}
                        value={value}
                        onChange={onChange}
                    />
                    {props.type == "password" ?
                        exibindoSenha
                            ?
                            <button className='botaoIcone' onClick={() => [setTypeAtual("password"), setExibindoSenha(false)]}><IoEye size={20} color="#888" /></button>
                            :
                            <button className='botaoIcone' onClick={() => [setTypeAtual("text"), setExibindoSenha(true)]}><IoEyeOffSharp size={20} color="#888" /></button>
                        :
                        <div></div>}
                </div>
                {button ?
                    <button className='buttonInput' onClick={onClick}>{icon == "IoSearchOutline" ? <IoSearchOutline size={24} /> : null}</button> : null
                }
            </div>
            <label className="mensagemErro">{mensagemErro}</label>
        </div >
    );
}