import ExibirRastreamentos from '../ExibirRastreamentos';
import Marcador from '../Marcador';

//Mui
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function OS(props) {
    const dataEmissaoOS = props.os.OSDATAEMISSAO;
    const numeroOS = props.numeroDaOS;
    const codEquip = props.os.IDOBJOF;
    const arrayStatus = [];
    arrayStatus["EM ANDAMENTO"] = "E";
    arrayStatus["NÃO INICIADO"] = "Y";
    arrayStatus["TERMINADO"] = "Z";
    arrayStatus["CANCELADO"] = "C";
    let statusOS = arrayStatus[props.os.STATUS_OS];

    return (
        <Accordion
            expanded={props.osAbertas.includes(props.numeroDaOS)}
            key={props.indice}
            style={{ borderRadius: 20, shadowColor: "#fff" }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon htmlColor="#333" />}
                onClick={() => props.exibirItensOS(numeroOS, dataEmissaoOS, codEquip, statusOS)}
                style={{ backgroundColor: "#fff", color: "#333", borderRadius: 20 }}
            >
                <Typography>
                    <div className='containerCabecalhoAccordion'>
                        <div className='containerAtributoCabecalhoAccordion' style={{ marginLeft: 0 }}>
                            <label style={{ fontSize: 26, color: "#333" }}>{props.os.OSNUMEROMOV}</label>
                        </div>
                        <div className='containerAtributoCabecalhoAccordion osDescricao'>
                            <h5>Descrição</h5>
                            <label>{props.os.DESCRICAO || "NÃO INFORMADO"}</label>
                        </div>
                        <div className='containerAtributoCabecalhoAccordion osLocalDeEstoque'>
                            <h5>Local de Estoque</h5>
                            <label>{props.os.LOCALESTOQUE}</label>
                        </div>
                        <div className='containerAtributoCabecalhoAccordion osCodEquip'>
                            <h5>Cod. Equip.</h5>
                            <label>{props.os.IDOBJOF || "Não Informado"}</label>
                        </div>
                        <div className='containerAtributoCabecalhoAccordion osDataAbertura'>
                            <h5>Data de Abertura</h5>
                            <label>{props.os.OSDATAEMISSAO}</label>
                        </div>
                        <div className='containerAtributoCabecalhoAccordion osDataEncerramento'>
                            <h5>Data de Encerramento.</h5>
                            <label>{props.os.ENCERRAMENTO || "-"}</label>
                        </div>
                        <div className='containerAtributoCabecalhoAccordion osDataEncerramento'>
                            <h5>Status</h5>
                            <label>{props.os.STATUS_OS || "-"}</label>
                        </div>
                    </div>
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                style={{ backgroundColor: "#fff", borderRadius: 20 }}
            >
                {props.itensDaOSAtual.map(function (item) {
                    let ultimoMovimento = item.ULTIMO_MOVIMENTO;
                    if (ultimoMovimento != undefined) {
                        ultimoMovimento = ultimoMovimento.split("-")[0].trim();
                    }
                    else {
                        ultimoMovimento = "nao informado";
                    }

                    let corStatus = "";

                    switch (ultimoMovimento) {
                        case "1.1.15":
                            corStatus = "#e57373";
                            break;

                        case "1.1.16":
                            corStatus = "#ff7043";
                            break;

                        case "1.1.01":
                            corStatus = "#3EA2ED";
                            break;

                        case "1.1.02":
                            corStatus = "#1976d2";
                            break;

                        case "1.1.03":
                            corStatus = "#777777";
                            break;

                        case "1.1.04":
                            corStatus = "#C99100";
                            break;

                        case "1.1.06":
                            corStatus = "#9c27b0";
                            break;

                        case "1.1.07":
                            corStatus = "#009688";
                            break;

                        case "1.1.08":
                            corStatus = "#f44336";
                            break;

                        case "1.2.01":
                            corStatus = "#06C13B";
                            break;

                        case "1.2.03":
                            corStatus = "#06C13B";
                            break;

                        case "1.2.04":
                            corStatus = "#06C13B";
                            break;

                        case "3.1.04":
                            corStatus = "#777777";
                            break;

                        // default:
                        //     corStatus = "#e57373";
                        //     break;
                    }

                    //Preenchendo rastreamentos
                    let rastreamentos = [];
                    if (item.PRIMOV != undefined) {
                        rastreamentos.push(
                            {
                                titulo: item.PRIMOV,
                                numero: item.PRINUMEROMOV,
                                dataEmissao: item.DATAEMISSAO,
                                responsavel: item.RESPONSAVEL_PRI,
                            }
                        );
                    }

                    if (item.SEGMOV != undefined) {
                        rastreamentos.push(
                            {
                                titulo: item.SEGMOV,
                                numero: item.SEGNUMEROMOV,
                                dataEmissao: item.SEGDATAEMISSAO,
                                tempoEmDias: item.DIFFDIASEGPRI,
                                responsavel: item.RESPONSAVEL_SEG,
                            }
                        );
                    }

                    if (item.TERCMOV != undefined) {
                        rastreamentos.push(
                            {
                                titulo: item.TERCMOV,
                                numero: item.TERCNUMEROMOV,
                                dataEmissao: item.TERCDATAEMISSAO,
                                tempoEmDias: item.DIFFDIATERCSEG,
                                responsavel: item.RESPONSAVEL_TERC,
                            }
                        );
                    }

                    if (item.CODCOTACAO != undefined) {
                        rastreamentos.push(
                            {
                                titulo: "Cotação",
                                numero: item.CODCOTACAO,
                                dataEmissao: item.DATACOTACAO,
                                status: item.STATUS_COTACAO,
                                responsavel: item.RESPONSAVEL_COTACAO != undefined ? item.RESPONSAVEL_COTACAO.toLowerCase() : ""
                            }

                        );
                    }

                    if (item.QUARTMOV != undefined) {
                        rastreamentos.push(
                            {
                                titulo: item.QUARTMOV,
                                numero: item.QUARTNUMEROMOV,
                                dataEmissao: item.QUARTDATAEMISSAO,
                                tempoEmDias: item.DIFFDIAQUATERC,
                                responsavel: item.COMPRADOR,
                            }
                        );
                    }

                    if (item.QUIMOV != undefined) {
                        rastreamentos.push(
                            {
                                titulo: item.QUIMOV,
                                numero: item.QUINUMEROMOV,
                                dataEmissao: item.QUIDATAEMISSAO,
                                tempoEmDias: item.DIFFDIAQUIQUA,
                                responsavel: item.RESPONSAVEL_QUI,
                            }
                        );
                    }

                    if (item.SEXMOV != undefined) {
                        rastreamentos.push(
                            {
                                titulo: item.SEXMOV,
                                numero: item.SEXNUMEROMOV,
                                dataEmissao: item.SEXDATAEMISSAO,
                                tempoEmDias: item.DIFFDIASEXQUI,
                                responsavel: item.RESPONSAVEL_SEX
                            }
                        );
                    }

                    let movimentoAtual = item.ULTIMO_MOVIMENTO;
                    if (item.ULTIMO_MOVIMENTO.includes("-")) {
                        movimentoAtual = item.ULTIMO_MOVIMENTO != undefined ? item.ULTIMO_MOVIMENTO.split("-")[0] + " - " + item.ULTIMO_MOVIMENTO.split("-")[1] : "Não Informado";
                    } else {
                        movimentoAtual = item.ULTIMO_MOVIMENTO != undefined ? item.ULTIMO_MOVIMENTO : "Não Informado";
                    }
                    let previsaoDeEntrega = item.ULTIMO_MOVIMENTO;
                    previsaoDeEntrega = previsaoDeEntrega != undefined ? previsaoDeEntrega.split("-")[2] : "";

                    return (
                        <div>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    style={{ backgroundColor: "#C6E6FE" }}
                                >
                                    <Marcador color={corStatus} pms={props.pms} />
                                    <Typography>
                                        <div style={{ display: "flex", alignItems: "center", width: "80vw" }}>
                                            <div className='atributosItemCabecalhoItemOS codigoDescricaoItem' style={{ marginLeft: 0 }}>{((item.CODIGOPRD == undefined) || (item.PRODUTO == undefined)) ? "INFORMAÇÃO NÃO EXISTENTE NO RM" : ((item.CODIGOPRD) + " - " + (item.PRODUTO))}</div>
                                            {/* <div className='atributosItemCabecalhoItemOS codigoDescricaoItem' style={{ marginLeft: 0 }}>{(item.CODIGOPRD || "Não Informado") + " - " + (item.PRODUTO || "Não Informado")}</div> */}
                                            <div className='atributosItemCabecalhoItemOS numeroMovimentoItem'>Mov. Atual: {movimentoAtual}</div>
                                            <div className='atributosItemCabecalhoItemOS previsaoEntregaItem'>Prev. Entrega: {previsaoDeEntrega}</div>
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    style={{ borderTop: "1px solid #ccc", backgroundColor: "#f2eecb" }}
                                >
                                    <Typography>
                                        <ExibirRastreamentos key={props.indice} rastreamentos={rastreamentos} item={item.CODIGOPRD + " - " + item.PRODUTO} setSemLicencas={props.setSemLicencas} />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    );
                })}
            </AccordionDetails>
        </Accordion>
    );
}