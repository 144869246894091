
function mascaraCodEquip(props) {
    var apenasNumeros = /[^0-9]/g;

    const qtdNumeros = props.replace(apenasNumeros, '').length;

    switch (qtdNumeros) {
        case 2:
            return props.replace(apenasNumeros, '').replace(/^(\d{1})(\d{1})$/, '$1.$2');
        case 5:
            return props.replace(apenasNumeros, '').replace(/^(\d{1})(\d{3})(\d{1})$/, '$1.$2.$3');
        case 8:
            return props.replace(apenasNumeros, '').replace(/^(\d{1})(\d{3})(\d{4})$/, '$1.$2.$3');
        default:
            return props.trim();
    }
}

export {
    mascaraCodEquip
}