export default function Marcador(props) {
    const color = props.color;
    
    let descricao = "";
    if (color == "#e57373") {
        descricao = "O.S";
    }
    else if(color == "#ff7043"){
        descricao = "O.S 3°";
    }
    else if (color == "#3EA2ED") {
        descricao = props.pms ? "PMS" : "Oficina";
    }
    else if (color == "#1976d2") {
        descricao = "Almoxarifado";
    }
    else if (color == "#777777") {
        descricao = "Baixado";
    }
    else if (color == "#C99100") {
        descricao = "Suprimentos";
    }
    else if (color == "#333") {
        descricao = "Cotações";
    }
    else if (color == "#9c27b0") {
        descricao = "Compras";
    }
    else if (color == "#009688") {
        descricao = "PCU";
    }
    else if (color == "#f44336") {
        descricao = "Diligenc.";
    }
    else if (color == "#06C13B") {
        descricao = "Disponível";
    }

    return (
        <div style={{ width: 100, textAlign: "center", color: "#fff", fontWeight: 500, borderRadius: 20, padding: 5, backgroundColor: color, marginRight: 10 }}>
            {descricao}
        </div>
    );
}